<template>
	<div class="bg-black text-white font-sans scroll-smooth">
		<section class="w-full relative font-sans min-h-screen bg-fixed bg z-10 bg-cover bg-center justify-end items-baseline pb-20 md:pb-80">
			<div class="bg-blend-lighten h-full bg-opacity-50">
				<nav class="flex items-center justify-between text-xl font-alata text-white">
					<div class="text-2xl font-medium text-orange-500">ow</div>
					<img alt="" class="transition-all duration-200 ease-in-out transform hover:scale-110" />
					<div class="text-white hidden h-10 font-alata md:flex md:space-x-8">
						<div class="text-white group transition-all duration-200 ease-in-out transform hover:scale-110">
							<a class="" href="#about">About</a>
							<div class="mx-2 group-hover:border-b group-hover:border-white"></div>
						</div>
						<div class="group transition-all duration-200 ease-in-out transform hover:scale-110">
							<a href="#myprojects">My Projects</a>
							<div class="mx-2 group-hover:border-b group-hover:border-white"></div>
						</div>
						<div class="group transition-all duration-200 ease-in-out transform hover:scale-110">
							<a href="#contact">Contact Me</a>
							<div class="mx-2 group-hover:border-b group-hover:border-white"></div>
						</div>
					</div>
				</nav>
			</div>
			<div class="animate-pulse absolute font-bold bottom-10 left-0 right-0 scroll-down">
				Scroll down to see more
				<div class="arrow">
					<i class="animate-pulse fas fa-solid fa-2x fa-arrow-down"></i>
				</div>
			</div>

			<div class="~text-white items-center text-center mt-96 font-bold font-sans">
				<h1 class="items-center waviy md:text-7xl">
					Hi i Am Oren Wohl A Full Stack
					<div class="dev">
						<span style="--i: 2">D</span>
						<span style="--i: 3">e</span>
						<span style="--i: 4">v</span>
						<span style="--i: 5">e</span>
						<span style="--i: 6">l</span>
						<span style="--i: 7">o</span>
						<span style="--i: 8">p</span>
						<span style="--i: 9">e</span>
						<span style="--i: 10">r</span>
					</div>
				</h1>
			</div>
		</section>
		<transition>
			<section id="" class="min-h-screen">
				<div class="mx-8 md:mx-0 flex flex-col pb-40 pt-20">
					<transition>
						<h1 id="about" class="text-white drop-shadow-md font-sans text-5xl font-thin mt-4 mb-20">About Me</h1>
					</transition>
					<div class="flex justify-center mb-20">
						<p class="font-sans aboutext max-w-xl text-xl text-center mb-20">
							I am a full stack developer Passionate about technology and enriching the knowledge. Highly motivated with problem-solving skills. A team player with a
							good interpersonal relation. currently working as a freelancer on a few projects
						</p>
					</div>

					<div class="flex mx-8 flex-col space-y-8 md:flex-row md:space-x-6 md:space-y-0 justify-center">
						<div class="box1 w-full flex flex-col justify-center items-center h-full">
							<div>
								<i class="fas fa-code fa-2x bg-orange-500 rounded-full p-6 mb-4"></i>
							</div>
							<div>
								<h2 class="text-xl font-sans">Technical Skills</h2>
								<p class="font-sans text-orange-400 max-w-xs mt-2 text-lg text-center">
									React, Vue.js, Python, Flask, Node.js, Express.js, ES6, Mongodb, Html, Css/Sass, TailwindCSS, Redux, Vuex
								</p>
							</div>
						</div>
						<div class="box2 w-full flex flex-col justify-center items-center h-full">
							<div>
								<i class="fas fa-book-reader fa-2x bg-orange-500 rounded-full p-6 mb-4"></i>
							</div>
							<div class="max-w-md">
								<h3 class="text-xl font-sans">CONSTANTLY LEARNING</h3>
								<p class="font-sans text-orange-400 max-w-xs mt-2 text-lg text-center">
									on all of my challenges, whenever I faced a new technology or deal with some issues I didn't face before, I managed to learn by myself very
									fast, and to come up with effective solutions.
								</p>
							</div>
						</div>
						<div class="box3 w-full flex flex-col justify-center items-center h-full">
							<div>
								<i class="fas fa-pencil-alt bg-orange-500 rounded-full p-6 fa-2x mb-4"></i>
							</div>
							<div class="max-w-md">
								<h3 class="text-xl font-sans">Self Disciplined</h3>
								<p class="text-orange-400 max-w-xs mt-2 text-lg text-center">
									Have the ability to manage myself with intent of achieving a goal, During my work i am very organized have a good time management and focus.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</transition>
		<section id="myprojects" class="hidden md:block">
			<div class="w-full projects bg-fixed min-h-screen bg-cover px-20 bg-center flex flex-col justify-center items-center">
				<h1 class="text-white text-5xl font-sans font-semibold mt-4 mb-60">My projects</h1>
				<div class="flex gap-8 mb-40 justify-center items-center text-white">
					<div class="w-full">
						<h1 class="text-4xl mb-6">Vue.js E-commerce store</h1>
						<div class="relative">
							<img src="../assets/vue ecommerce.png" class="h-96" alt="" />
							<div
								class="flex flex-col gap-4 justify-center items-center bg-orange-500 w-full h-96 absolute opacity-0 hover:opacity-90 transition-all duration-200 top-0 left-0"
							>
								<p class="text-black max-w-xs">E-commerce store with main page and a shopping cart tech use vue.js, vuex, TailwindCSS</p>
								<ul class="text-black">
									<li class="hover:scale-125 transition-all duration-200">
										<a class="text-black" href="https://github.com/orenwohl/oren-vue-e-commerce" target="_blank">To repository</a>
									</li>
									<li class="hover:scale-125 transition-all duration-200 text-black">
										<a class="text-black" href="https://vue-oren-store.netlify.app/" target="_blank"> Live Demo</a>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div class="w-full">
						<h1 class="text-4xl mb-6">React E-commerce store</h1>
						<div class="relative">
							<img src="../assets/react e commerce.png" class="h-96" alt="" />
							<div
								class="flex flex-col gap-4 justify-center items-center bg-orange-500 w-full h-96 absolute opacity-0 hover:opacity-90 transition-all duration-200 top-0 left-0"
							>
								<p class="text-black max-w-xs">
									E-commerce store with main page and a shopping cart categories pages andl login tech use react.js, redux thunk and saga, sass and firebase
								</p>
								<ul class="text-black">
									<li class="hover:scale-125 transition-all duration-200">
										<a class="text-black" href="https://github.com/orenwohl/oren-ecommerce-store" target="_blank">To repository</a>
									</li>
									<li class="hover:scale-125 transition-all duration-200 text-black">
										<a class="text-black" href="https://react-oren-ecommerce.netlify.app/" target="_blank"> Live Demo</a>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div class="w-full">
						<h1 class="text-4xl mb-6">Dummy Hotel Website</h1>
						<div class="relative">
							<img src="../assets/hotel.png" class="h-96" alt="" />
							<div
								class="flex flex-col gap-4 justify-center items-center bg-orange-500 w-full h-96 absolute opacity-0 hover:opacity-90 transition-all duration-200 top-0 left-0"
							>
								<p class="text-black max-w-xs">Hotel dummy website with 3 views fully responsive using react.js and css</p>
								<ul class="text-black">
									<li class="hover:scale-125 transition-all duration-200">
										<a class="text-black" href="https://github.com/orenwohl/oren-hotel" target="_blank">To repository</a>
									</li>
									<li class="hover:scale-125 transition-all duration-200 text-black">
										<a class="text-black" href="https://oren-hotel.netlify.app/" target="_blank"> Live Demo</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="contact" id="contact">
			<div class="bg-orange-500 pt-16 pb-28 flex flex-col md:flex-row justify-center items-center space-y-4 px-40">
				<div class="flex flex-col justify-center items-center gap-4 w-1/3 text-black">
					<a class="text-black flex flex-col justify-center items-center" href="https://github.com/orenwohl" target="_blank">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-28 w-40 hover:scale-110 transition-all duration-200" viewBox="0 0 496 512">
							<!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
							<path
								d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
							/>
						</svg>
						<h3 class="text-2xl font-extrabold">To My Github</h3></a
					>
				</div>

				<div class="flex flex-col justify-center items-center gap-4 w-1/3 text-black">
					<a
						class="text-black flex flex-col justify-center items-center"
						href="http://www.linkedin.com/in/orenwohl
"
						target="_blank"
					>
						<svg class="h-28 w-40 hover:scale-110 transition-all duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
							<!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
							<path
								d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
							/>
						</svg>
						<h3 class="text-2xl font-extrabold">Linkdin Profile</h3>
					</a>
				</div>

				<div class="flex flex-col justify-center items-center gap-4 w-1/3 text-black">
					<svg class="h-28 w-40 hover:scale-110 transition-all duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
						<!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
						<path
							d="M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM48 128V150.1L220.5 291.7C241.1 308.7 270.9 308.7 291.5 291.7L464 150.1V127.1C464 119.2 456.8 111.1 448 111.1H64C55.16 111.1 48 119.2 48 127.1L48 128zM48 212.2V384C48 392.8 55.16 400 64 400H448C456.8 400 464 392.8 464 384V212.2L322 328.8C283.6 360.3 228.4 360.3 189.1 328.8L48 212.2z"
						/>
					</svg>

					<h3 class="text-2xl font-extrabold">ORENWOHLWOHL@GMAIL.COM</h3>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

export default {
	name: "HomeView",
	components: {},

	mounted() {
		gsap.fromTo(".dev", { duration: 1, opacity: 0, y: 120, delay: 0.5 }, { duration: 2, opacity: 1, y: 0, delay: 0.5 });

		let tl = gsap.timeline({
			// yes, we can add it to an entire timeline!
			scrollTrigger: {
				trigger: "#about",
				start: "bottom bottom", // when the top of the trigger hits the top of the viewport
				end: "+=700", // end after scrolling 500px beyond the start
				scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
			},
		});

		// add animations and labels to the timeline
		tl.from("#about", { scale: 0.5, x: -450 }).to("#about", { x: 0 });

		let tl2 = gsap.timeline({
			// yes, we can add it to an entire timeline!
			scrollTrigger: {
				trigger: "#about",
				start: "bottom +=700", // when the top of the trigger hits the top of the viewport
				end: "+=700", // end after scrolling 500px beyond the start
				scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
			},
		});

		// add animations and labels to the timeline
		tl2.from(".aboutext", { opacity: 0, y: -450 }).to(".aboutext", { x: 0 });

		let tl3 = gsap.timeline({
			// yes, we can add it to an entire timeline!
			scrollTrigger: {
				trigger: "#about",
				start: "bottom +=700", // when the top of the trigger hits the top of the viewport
				end: "+=500", // end after scrolling 500px beyond the start
				scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
			},
		});

		// add animations and labels to the timeline
		tl3.from(".box1", { opacity: 0, duration: 3 }).to(".box1", { delay: 1, x: 0 });
		tl3.from(".box2", { opacity: 0, rotate: 360, duration: 3 }).to(".box2", { delay: 1, x: 0 });
		tl3.from(".box3", { opacity: 0, duration: 2 }).to(".box3", { delay: 1, x: 0 });
	},
};
</script>

<style scoped>
#hero {
	background: black;
	height: 100vh;
}
</style>
